// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.template {
  position: relative;
  &.name {
    display: none;
  }
  .button-clone {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: lightgrey;
    cursor: pointer;
    border-radius: 2px;
    transition: linear 0.3s;

    &:hover {
      background: white;
    }
    &.active {
      i {
        color: #3699ff;
      }
    }
  }
}

.box-cheked-show {
  display: none;

  .title {
    display: block;
    line-height: 2;
    padding: 12px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  .option-box {
    .group-name.validate {
      .name {
        color: red;
      }
      input {
        border: 1px solid red;
      }
    }

    .option-list {
      margin: 1rem 0 0 3rem;
    }
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .option-delete {
    width: fit-content;
  }
}

.btn-custom {
  @include flex-center();
  padding: 4px 12px;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  &:hover {
    background: #f3f6f9;
  }
}
.btn-custom-2 {
  @include flex-center();
  padding: 4px 12px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #fff;

  &:hover {
    background: #f8edff;
  }
}
.btn-custom-3 {
  padding: 4px 12px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  border-color: #e4e4e4;
  border-style: solid;

  &:hover {
    background: #f3f6f9;
    border-color: #3498db;
  }
  &.clicked {
    border-color: #3498db;
  }
}
.card-style {
  padding: 4px 12px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  min-width: 250px;
}

.span-border {
  display: block;
  width: 100%;
  border: 1px solid #f3f6f9;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}
.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.ck-content .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.apexcharts-menu-item.exportSVG {
  display: none !important;
}
.jodit-dialog__box .jodit-dialog {
  max-height: 80% !important;
  max-width: 80% !important;
}
.jodit-dialog__box .jodit-dialog__header {
  overflow: initial !important;
}
.jodit-dialog__box .jodit-dialog__content {
  flex: 1 !important;
}

.bg {
  background-color: rgb(255, 255, 255);
}
.qr-size-A5 {
  max-width: 100%; /* Set maximum width to 100% of the container */
  height: auto; /* Maintain aspect ratio */
  max-width: 596px; /* Set maximum width to the original width of the image */
  max-height: 840px; /* Set maximum height to the original height of the image */
}

@media only screen and (max-width: 600px) {
  .qr-size-A5 {
    max-width: 20%; /* Set maximum width to 100% of the container */
    height: auto; /* Maintain aspect ratio */
  }
}
.custom-select {
  position: relative;
  font-family: Arial;
  background-color: white;
  box-shadow: 10px 5px 5px lightgrey;
  border-color: lightgrey;
  border-style: solid;
  border-width: thin;
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
}
.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.form-inline input[type="checkbox"] {
  margin-right: 5px;
}
.form-inline .checkbox-container {
  display: flex;
  align-items: center;
  margin: 10px 10px 0px 50px;
}
@media (max-width: 800px) {
  .form-inline input {
    margin: 10px 0;
  }

  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }
  .form-inline .checkbox-container {
    margin: 10px 0;
  }
}
.input-col-4 {
  width: 33.33%;
  min-width: 200px;
}
.input-col-6 {
  width: 50%;
  min-width: 200px;
}
.promotion-selection {
  display: flex;
  flex-direction: column;
}

.radio-card {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.radio-card:hover {
  background-color: #f9f9f9;
}

.radio-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.radio-icon {
  margin-right: 15px;
}

.radio-icon img {
  width: 40px;
  height: 40px;
}

.radio-details {
  flex-grow: 1;
  padding: 18px;
}

.radio-details h5 {
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}

.radio-details p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #666;
}

.radio-arrow {
  margin-left: auto;
  color: #888;
}

.radio-arrow .fa {
  font-size: 1.5em;
}

.planes-radio {
  display: none;
}
.rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ccc;
  cursor: pointer;
  margin: 0 5px;
}

.selected {
  background-color: #555; /* Dark circle color */
  color: white;
}

.circle-number {
  font-size: 18px;
  font-weight: bold;
}

.label {
  margin-top: 5px;
  font-size: 12px;
  color: #000;
}

.promotion-item {
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100%;
}
.promotion-img {
  width: 50px;
  height: 50px;
}

.promotion-sarabun-text {
  font-family: "Sarabun", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}

.promotion-sarabun-strong {
  font-weight: 600;
  padding-left: 27px;
  font-family: "Sarabun", sans-serif;
  font-size: 16px;
  line-height: 23.4px;
  text-align: left;
}

.promotion-view-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 0.5rem;
  padding: 0.5rem;
}

.promotion-view-tags::-webkit-scrollbar {
  display: none; /* แถบ scrolling สำหรับ Chrome, Safari, และ Edge */
}

.menu-form-label {
  margin-bottom: 0;
  border-width: 0;
  width: 20%;
  min-width: 120px;
  flex-shrink: 0;
}
.menu-accordion-form-label {
  margin-bottom: 0;
  border-width: 0;
  width: 20%;
  min-width: 140px;
  flex-shrink: 0;
  flex-basis: 20%;
}

.menu-form-input {
  height: 38px;
  width: 80%;
  background-color: #F4F6F9;
  border-radius: 5px;
  border-width: 0;
  flex-grow: 1;
}
.menu-accordion-form-input {
  height: 38px;
  width: 80%;
  background-color: #F4F6F9;
  border-radius: 5px;
  border-width: 0;
  flex: 1;
  min-width: 200px;
}

.menu-btn-custom {
  min-width: 120px;
  max-width: 200px;
}